import wrapWithProvider from "./wrapWithProvider";
export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () => {
  if (
    typeof window !== "undefined" &&
    !document.getElementById("portal-chat")
  ) {
    const portalDiv = document.createElement("div");
    portalDiv.id = "portal-chat";
    document.body.appendChild(portalDiv);
  }
};
